import React from 'react'
import Layout from '../../components/layout/Layout';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentPratica from '../../components/ContentPratica';
import { LeftEredita, RightEredita } from '../../components/pratiche-auto/eredita-veicolo/EreditaVeicolo';

const EreditaVeicolo = () => {
    return (
        <Layout>
            <PageNavigator link="/pratiche-auto" text="Pratiche auto" />
            <ContentPratica id="eredita-veicolo" title="Eredità Veicolo">
                <LeftEredita />
                <RightEredita />

            </ContentPratica>

        </Layout>
    )
}

export default EreditaVeicolo
